import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { TweenMax, Linear, Power3 } from 'gsap';
import { getDarkerColor } from 'functions';
import { useAnimate } from 'hooks';
import { ANIMATION } from 'utils/constants';
import { CircleText } from 'svgs';
import styles from './playlistButtonSelect.module.scss';

const TIMEOUT = 100;

const PlaylistButtonSelect = ({ playlist }) => {
  const btnContent = useRef();
  const btnWrapper = useRef();
  const themeColor = playlist ? playlist.primaryColor : '#ffffff';
  const spotColor = playlist
    ? playlist.accentColor
      ? playlist.accentColor
      : getDarkerColor(themeColor)
    : '#15181e';
  const animate = useAnimate();
  const destination = `/${playlist.slug}`;
  const label = playlist.title;

  const handleMouseEnter = () => {
    const btnContentTag = btnContent.current;

    setTimeout(() => {
      TweenMax.fromTo(
        btnContentTag,
        6,
        { rotation: 0 },
        {
          rotation: 360,
          ease: Linear.easeNone,
          repeat: -1,
        },
      );
    }, TIMEOUT);
  };

  const handleMouseLeave = () => {
    const btnContentTag = btnContent.current;

    setTimeout(() => {
      TweenMax.to(btnContentTag, 0.6, { rotation: 0, ease: Power3.easeInOut });
    }, TIMEOUT);
  };

  useEffect(() => {
    const btnWrapperTag = btnWrapper.current;
    btnWrapperTag.addEventListener('mouseenter', handleMouseEnter);
    btnWrapperTag.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      btnWrapperTag.removeEventListener('mouseenter', handleMouseEnter);
      btnWrapperTag.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <TransitionLink
      className={styles.btnSelect}
      style={{ '--darker-color': spotColor, '--theme-color': themeColor }}
      to={destination}
      exit={{
        trigger: ({ node }) => {
          const element = node.querySelector('main');
          animate(element, -1, 'EXIT');
        },
        length: ANIMATION.DURATION,
      }}
      entry={{
        trigger: ({ node }) => {
          const element = node.querySelector('main');
          animate(element, -1, 'ENTER');
        },
        delay: 0,
        length: ANIMATION.DURATION,
      }}>
      <div ref={btnWrapper} className={styles.wrapper}>
        <div ref={btnContent} className={styles.content}>
          <CircleText text={label} />
        </div>
      </div>
    </TransitionLink>
  );
};

PlaylistButtonSelect.propTypes = {
  playlist: PropTypes.object,
};

export default PlaylistButtonSelect;
