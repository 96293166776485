import { animate } from 'functions';
import { ANIMATION } from 'utils/constants';

function useExitAnimate() {
  return (element, DIRECTION, STATE) => {
    const ANIMATION_STATE = STATE === 'EXIT' ? ANIMATION.EXIT : ANIMATION.ENTER;

    animate(element, STATE, 'mobile', DIRECTION * ANIMATION_STATE, ANIMATION.DURATION);
  };
}

export default useExitAnimate;
