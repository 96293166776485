import React, { useState } from 'react';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import { className } from 'utils';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from './typefaceFooter.module.scss';

const TypefaceFooter = ({ className: customClassName, typeface }) => {
  const designersWithSites = typeface.designers
    ? typeface.designers.filter(designer => designer.website)
    : false;

  const [descIsVisible, setDescIsVisible] = useState(false);

  const toggleDescription = () => {
    setDescIsVisible(!descIsVisible);
  };

  return (
    <footer {...className(customClassName, styles.typefaceFooter)}>
      {typeface.description && (
        <div className={styles.typefaceDescription}>
          <label
            {...className(styles.footerTitle, styles.infoLink, styles.descriptionToggleLabel)}
            htmlFor={`${kebabCase(typeface.name)}-desc-toggle`}>
            <input
              id={`${kebabCase(typeface.name)}-desc-toggle`}
              className={styles.descriptionToggleInput}
              name={`${kebabCase(typeface.name)}-desc-toggle`}
              type="checkbox"
              onChange={toggleDescription}
            />
            About {typeface.name}
          </label>
          <div {...className(styles.descriptionWrapper, descIsVisible && styles.visible)}>
            {documentToReactComponents(typeface.description.json)}
          </div>
        </div>
      )}
      <div className={styles.footerLinks}>
        <a
          href={typeface.downloadLink}
          {...className(styles.footerTitle, styles.buyLink)}
          rel="noopener noreferrer"
          target="_blank">
          Buy {typeface.name}
        </a>
        {typeface.foundry && (
          <a
            {...className(styles.footerTitle, styles.externalLink)}
            href={typeface.foundry.website}
            rel="noopener noreferrer"
            target="_blank">
            {typeface.foundry.name}
          </a>
        )}
        {designersWithSites &&
          designersWithSites.map(
            (designer, i) =>
              designer.website && (
                <a
                  href={designer.website}
                  rel="noopener noreferrer"
                  target="_blank"
                  {...className(styles.footerTitle, styles.externalLink)}
                  key={i}>
                  {designer.name}
                </a>
              ),
          )}
      </div>
    </footer>
  );
};

TypefaceFooter.propTypes = {
  className: PropTypes.string,
  typeface: PropTypes.object.isRequired,
};

export default TypefaceFooter;
