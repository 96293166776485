import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { PlaylistTitle } from 'components';
import styles from './playlistHeader.module.scss';

const PlaylistHeader = ({ content }) => (
  <header className={styles.playlistHeader}>
    <div className={styles.playlistInfo}>
      <PlaylistTitle
        authorName={content.author[0].name}
        authorWebsite={content.author[0].website}
        title={content.title}
        className={styles.playlistTitle}
      />
      {content.description && (
        <div className={styles.playlistDescription}>
          {documentToReactComponents(content.description.json)}
        </div>
      )}
    </div>
  </header>
);

PlaylistHeader.propTypes = {
  content: PropTypes.object,
};

export default PlaylistHeader;
