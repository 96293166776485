import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Power3 } from 'gsap';
import { useArrayRef, useDetectSSR } from 'hooks';
import { EVENTS, IDLE_INTERVAL, IDLE_TIMEOUT } from 'utils/constants';
import { PlaylistButtonSelect } from 'components';
import styles from './playlistIndex.module.scss';

const BREAKPOINT = parseInt(styles.varBreakpoint);

const PlaylistIndex = ({ allPlaylists }) => {
  const [buttonsRef, setButtonRef] = useArrayRef();
  const idleTimeout = useRef(null);
  const idleInterval = useRef(null);
  const isAnimationRunning = useRef(false);
  const isSSR = useDetectSSR();
  const isMobile = useRef(!isSSR && window.innerWidth <= BREAKPOINT);

  const idleAnimation = async () => {
    if (!isAnimationRunning.current && !isMobile.current) {
      isAnimationRunning.current = true;
      const animationPromises = buttonsRef.current.map(
        (buttonTag, i) =>
          new Promise(resolve => {
            TweenMax.fromTo(
              buttonTag,
              0.6,
              { y: `0%` },
              {
                y: `-50%`,
                ease: Power3.easeOut,
                repeat: 1,
                yoyo: true,
                onComplete: resolve,
              },
            ).delay(i / 5);
          }),
      );
      await Promise.all(animationPromises);
      isAnimationRunning.current = false;
    }
  };

  const clearIdle = () => {
    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
      idleTimeout.current = null;
    }

    if (idleInterval.current) {
      clearInterval(idleInterval.current);
      idleInterval.current = null;
    }
  };

  const setIdleInterval = () => {
    clearIdle();
    idleAnimation();
    idleInterval.current = setInterval(idleAnimation, IDLE_INTERVAL);
  };

  const setIdleTimout = () => {
    idleTimeout.current = setTimeout(() => {
      setIdleInterval();
    }, IDLE_TIMEOUT);
  };

  const handleMouseEnter = (e, i) => {
    if (!isMobile.current) {
      const buttonTag = buttonsRef.current[i];
      TweenMax.to(buttonTag, 0.6, { y: `-50%`, ease: Power3.easeOut });
    }
  };

  const handleMouseLeave = (e, i) => {
    if (!isMobile.current) {
      const buttonTag = buttonsRef.current[i];
      TweenMax.to(buttonTag, 0.6, { y: `0%`, ease: Power3.easeInOut });
    }
  };

  const handleResize = () => {
    if (window.innerWidth > BREAKPOINT) {
      isMobile.current = false;
    } else if (!isMobile.current) {
      isMobile.current = true;
    }
  };

  const startTimer = () => {
    setIdleTimout();
  };

  const goActive = () => {
    // start timer startTimer();
    startTimer();
  };

  const resetTimer = () => {
    // Clear timeout
    clearIdle();

    // Go active goActive();
    goActive();
  };

  useEffect(() => {
    if (!isSSR) {
      setIdleTimout();
      EVENTS.forEach(event => {
        document.addEventListener(event, resetTimer, false);
      });

      window.addEventListener('resize', handleResize);

      return () => {
        EVENTS.forEach(event => {
          document.removeEventListener(event, resetTimer, false);
        });

        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isSSR]);

  return (
    <section className={styles.playlistIndex}>
      {allPlaylists.map((playlist, i) => (
        <div
          key={i}
          className={styles.playlistButton}
          ref={setButtonRef(i)}
          onMouseLeave={e => handleMouseLeave(e, i)}
          onMouseEnter={e => handleMouseEnter(e, i)}>
          <div className={styles.playlistButtonContent}>
            <PlaylistButtonSelect playlist={playlist.node} color={playlist.primaryColor} />
          </div>
        </div>
      ))}
    </section>
  );
};

PlaylistIndex.propTypes = {
  allPlaylists: PropTypes.array,
};

export default PlaylistIndex;
