import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useAnimate } from 'hooks';
import { ANIMATION } from 'utils/constants';
import { ExternalLink } from 'components';
import { Arena } from 'svgs';

import styles from './header.module.scss';

const Header = ({ siteTitle, aboutIsOpen, setAboutIsOpen }) => {
  const animate = useAnimate();
  const DIRECTION = 1;

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <h1 className={styles.appName}>
          <TransitionLink
            exit={{
              trigger: ({ node }) => {
                const element = node.querySelector('main');
                animate(element, DIRECTION, 'EXIT');
              },
              length: ANIMATION.DURATION,
            }}
            entry={{
              trigger: ({ node }) => {
                const element = node.querySelector('main');
                animate(element, DIRECTION, 'ENTER');
              },
              delay: 0,
              length: ANIMATION.DURATION,
            }}
            className={styles.appLink}
            to="/">
            {siteTitle} <span className={styles.conjunction}>by </span>
            <span className={styles.credit}>Upstatement</span>
          </TransitionLink>
        </h1>
        <div className={styles.links}>
          <button
            className={aboutIsOpen ? styles.closeButton : styles.infoButton}
            onClick={() => setAboutIsOpen(!aboutIsOpen)}>
            {aboutIsOpen ? 'Close' : 'Info'}
          </button>

          <span className={styles.arenaIcon}>
            <ExternalLink
              to="https://www.are.na/upstatement/typelists"
              noRotate
              title="See our inspiration here">
              <Arena />
            </ExternalLink>
          </span>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  aboutIsOpen: PropTypes.bool,
  setAboutIsOpen: PropTypes.func,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
