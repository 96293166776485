import React from 'react';
import PropTypes from 'prop-types';
import styles from './typefaceInfo.module.scss';

const creditsWithFoundry = ({ name, website }, designers) => (
  <>
    <a href={website} target="_blank" rel="noopener noreferrer" className={styles.foundryLink}>
      {name}
    </a>
    {designers && ` ft. ${designers}`}
  </>
);

const TypefaceInfo = ({ designers, foundry, name }) => {
  const designerNames = () =>
    designers && designers.length > 0 ? designers.map(designer => designer.name).join(', ') : false;

  return (
    <h1 className={styles.typefaceInfoWrapper}>
      <span className={styles.typefaceInfo}>{name}</span> {designers || foundry ? `by ` : null}
      <span className={styles.typefaceInfo}>
        {foundry ? creditsWithFoundry(foundry, designerNames()) : designerNames()}
      </span>
    </h1>
  );
};

TypefaceInfo.propTypes = {
  designers: PropTypes.array,
  foundry: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
  }),
  name: PropTypes.string,
};

creditsWithFoundry.propTypes = {
  name: PropTypes.string,
  website: PropTypes.string,
};

export default TypefaceInfo;
