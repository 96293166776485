import Color from 'color';

const DARKEN = 0.625;
const DESATURATE = 0.85;

const getDarkerColor = color =>
  Color(color)
    .darken(DARKEN)
    .desaturate(DESATURATE)
    .string();

export default getDarkerColor;
