import React, { useRef } from 'react';
import { copyToClipboard } from 'functions';
import { COPY_DURATION } from 'utils/constants';
import { ExternalLink } from 'components';
import styles from './about.module.scss';

const About = () => {
  const buttonRef = useRef();

  const handleClick = () => {
    copyToClipboard('typelist@upstatement.com');
    buttonRef.current.innerText = 'Copied!';

    setTimeout(() => {
      buttonRef.current.innerText = 'Contact us';
    }, COPY_DURATION);
  };

  return (
    <section className={styles.about}>
      <div className={styles.body}>
        <p>
          Welcome to our typelists! Overwhelmed by the endless array of type options, we set out to
          create a friendly, snack-size sampling of typefaces. We’re curating collections of
          typefaces that embody the aesthetic of a time, place, or very specific artifact (a
          Tarantino film, vintage matchboxes, general store groceries…) We even crafted custom
          pangrams to show off our typefaces (
          <ExternalLink to="https://pangram.me/en">Try it!</ExternalLink> It’s easier than you’d
          think.). Dig in for inspiration, reference, and fun.
        </p>
        <p>
          We hope this is a good way to support the creative process of choosing a typeface. It’s a
          trend-free, democratic font zone, a way for designers to refresh themselves.
        </p>
        <p>If you have an idea—the weirder the better—let us know! We’d love to collaborate.</p>
      </div>

      <footer className={styles.footer}>
        <div>
          <ExternalLink to="https://www.upstatement.com/">♥ Upstatement</ExternalLink>
        </div>

        <div>
          <button onClick={handleClick} ref={buttonRef}>
            Contact us
          </button>
        </div>
      </footer>
    </section>
  );
};

export default About;
