import React from 'react';
import PropTypes from 'prop-types';
import { className } from 'utils';
import styles from './playlistTitle.module.scss';

const PlaylistTitle = ({ authorName, authorWebsite, className: customClassName, title }) => {
  const AuthorTag = authorWebsite ? `a` : `span`;

  return (
    <h1 {...className(styles.playlistTitle, customClassName)}>
      <span className={styles.playlistName}>{title}</span>{' '}
      <span className={styles.conjunction}>by </span>
      <AuthorTag
        className={styles.playlistAuthor}
        href={authorWebsite ? authorWebsite : undefined}
        rel={authorWebsite ? 'author noopener noreferrer' : undefined}
        target={authorWebsite ? '_blank' : undefined}>
        {authorName}
      </AuthorTag>
    </h1>
  );
};

PlaylistTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorWebsite: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PlaylistTitle.defaultProps = {
  authorWebsite: false,
};

export default PlaylistTitle;
