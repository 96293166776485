import React from 'react';
import PropTypes from 'prop-types';

const CircleText = ({ text }) => (
  <svg viewBox="0 0 130 130" xmlns="http://www.w3.org/2000/svg">
    <path id="path" fill="none" d="M0,65a65,65 0 1,0 130,0a65,65 0 1,0 -130,0" />

    <text fontFamily="Untitled Sans" fontSize="16" wordSpacing={5} letterSpacing="2">
      <textPath href="#path">{text.toUpperCase()}</textPath>
    </text>
  </svg>
);

CircleText.propTypes = {
  text: PropTypes.string,
};

export default CircleText;
