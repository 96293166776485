import React from 'react';

const Arena = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 89">
    <path
      fill="#000000"
      d="M149.93,62.356 L129.083,45.972 C127.807,44.972 127.807,43.33 129.083,42.327 L149.931,25.947 C151.211,24.945 151.746,23.252 151.121,22.187 C150.495,21.125 148.747,20.747 147.241,21.347 L122.451,31.221 C120.944,31.827 119.524,31.001 119.298,29.391 L115.57,2.926 C115.34,1.317 114.13,0 112.877,0 C111.63,0 110.421,1.317 110.197,2.925 L106.467,29.392 C106.239,31.002 104.821,31.826 103.312,31.222 L78.932,21.512 C77.42,20.91 74.957,20.91 73.449,21.512 L49.065,31.222 C47.557,31.826 46.137,31.002 45.911,29.392 L42.186,2.925 C41.956,1.317 40.748,0 39.5,0 C38.248,0 37.037,1.317 36.812,2.925 L33.082,29.392 C32.856,31.002 31.437,31.826 29.929,31.222 L5.14,21.35 C3.633,20.747 1.888,21.127 1.262,22.188 C0.637,23.254 1.17,24.948 2.446,25.948 L23.296,42.328 C24.573,43.331 24.573,44.973 23.296,45.974 L2.446,62.356 C1.166,63.358 0.636,65.152 1.26,66.34 C1.887,67.53 3.632,68.008 5.137,67.404 L29.704,57.538 C31.214,56.935 32.618,57.756 32.829,59.366 L36.373,86.062 C36.587,87.669 37.991,88.985 39.493,88.985 C40.993,88.985 42.398,87.67 42.613,86.062 L46.163,59.366 C46.373,57.756 47.783,56.936 49.285,57.538 L73.449,67.236 C74.955,67.842 77.419,67.842 78.926,67.236 L103.086,57.538 C104.59,56.935 105.996,57.756 106.211,59.366 L109.761,86.062 C109.973,87.669 111.378,88.985 112.876,88.985 C114.378,88.985 115.783,87.67 115.996,86.062 L119.546,59.366 C119.762,57.756 121.166,56.936 122.67,57.538 L147.24,67.404 C148.74,68.008 150.49,67.529 151.116,66.341 C151.743,65.155 151.21,63.361 149.931,62.359 L149.93,62.356 Z M96.89,46.18 L78.53,60.315 C77.245,61.305 75.137,61.305 73.856,60.315 L55.49,46.18 C54.206,45.19 54.196,43.56 55.47,42.555 L73.87,28.062 C75.144,27.057 77.233,27.057 78.508,28.062 L96.908,42.555 C98.185,43.559 98.175,45.189 96.888,46.181 L96.89,46.18 Z"
      transform="translate(-1)"></path>
  </svg>
);

export default Arena;
