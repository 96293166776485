import { TweenMax, Power3 } from 'gsap';

const EASE = Power3.easeInOut;

const animate = (element, state, device, DIRECTION, DURATION) => {
  // Handle desktop animations, entering/exiting
  if (device === 'desktop') {
    if (state === 'EXIT') {
      return TweenMax.to(element, DURATION, {
        autoAlpha: 0,
        x: `${DIRECTION}%`,
        ease: EASE,
      });
    } else if (state === 'ENTER') {
      TweenMax.fromTo(
        element,
        DURATION,
        { x: `${DIRECTION * -1}%`, ease: EASE },
        { x: '0%', ease: EASE },
      );
    }
  } else if (device === 'mobile') {
    // Handle mobile animations, entering/exiting
    if (state === 'EXIT') {
      TweenMax.fromTo(
        element,
        DURATION,
        { autoAlpha: 1, y: '0vh' },
        {
          autoAlpha: 0,
          y: `${DIRECTION}vh`,
          ease: EASE,
        },
      );
    } else if (state === 'ENTER') {
      TweenMax.fromTo(
        element,
        DURATION,
        { y: `${DIRECTION * -1}vh`, autoAlpha: 0, ease: EASE },
        { y: '0vh', autoAlpha: 1, ease: EASE },
      );
    }
  }
};

export default animate;
