import React from 'react';
import PropTypes from 'prop-types';
import { TypefaceInfo, TypefaceFooter } from 'components';
import styles from './typeface.module.scss';

const Typeface = ({ typeface }) => {
  return (
    <article className={styles.typeface}>
      <TypefaceInfo
        designers={typeface.designers}
        foundry={typeface.foundry}
        name={typeface.name}
      />
      {typeface.pangramImage && (
        <div className={styles.typefaceSpecimen}>
          <img src={typeface.pangramImage.file.url} alt="Specimen" />
        </div>
      )}
      <TypefaceFooter typeface={typeface} />
    </article>
  )
};

Typeface.propTypes = {
  typeface: PropTypes.object.isRequired,
};

export default Typeface;
