import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const ExternalLink = ({ children, to, title }) => {
  const linkRef = useRef();

  return (
    <a
      className={styles.link}
      href={to}
      ref={linkRef}
      rel="noopener noreferrer"
      target="_blank"
      title={title && title}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ExternalLink;
