import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { className } from 'utils';
import { Location } from '@reach/router';
import { About, Header } from 'components';
import styles from './layout.module.scss';
import '../../styles/styles.scss';

const Layout = forwardRef(({ children, isIndex }, ref) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [aboutIsOpen, setAboutIsOpen] = useState(false);

  return (
    <Location>
      {({ location }) => (
        <div
          {...className(styles.appContainer, location.pathname === '/' && styles.appContainerIndex)}
          ref={ref}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            setAboutIsOpen={setAboutIsOpen}
            aboutIsOpen={aboutIsOpen}
          />

          <main className={isIndex ? styles.appContentIndex : styles.appContent}>
            {aboutIsOpen ? <About /> : <>{children}</>}
          </main>
        </div>
      )}
    </Location>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isIndex: PropTypes.bool,
};

Layout.displayName = Layout;

export default Layout;
