export const ANIMATION = {
  DURATION: 1,
  ENTER: 100,
  EXIT: 50,
};

export const DESKTOP_WIDTH = '1200';

export const COPY_DURATION = '2000';

export const IDLE_TIMEOUT = 10000; // 10s
export const IDLE_INTERVAL = 15000; // 15s

export const EVENTS = [
  'mousemove',
  'mousedown',
  'keypress',
  'DOMMouseScroll',
  'mousewheel',
  'touchmove',
  'MSPointerMove',
];
