import React from 'react';
import PropTypes from 'prop-types';
import { Typeface } from 'components';
import styles from './typefaceList.module.scss';

const TypefaceList = ({ content }) => (
  <section className={styles.typefaceList}>
    <ul className={styles.fontList}>
      {content.typefaces.map((typeface, key) => (
        <li key={key}>
          <Typeface typeface={typeface} />
        </li>
      ))}
    </ul>
  </section>
);

TypefaceList.propTypes = {
  content: PropTypes.object,
};

export default TypefaceList;
